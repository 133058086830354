.feature-section {
  max-width: 1220px;
  max-height: 3920px;
  background: #f0e9ff;
  border-radius: 42px;
  margin-top: 60px;
  margin-left: 5.5rem;
  text-align: center;
  overflow: hidden;
}
.feature-tag > span {
  font-family: Work Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #7f01fc;

  position: relative;
  top: 30px;
}
.feature-tag > p {
  font-family: Work Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.46px;
  margin-top: 40px;
  padding: 10px;
}
.Eva {
  height: 468px;
  background-color: #f7f5fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.starimg1 {
  display: inline;
  position: relative;
  top: -15px;
  left: -10px;
}
.starimg2 {
  display: inline;
  position: relative;
  top: 15px;
  left: 22rem;
}

.text-section {
  /* display: flex; */
  position: relative;
  left: -5rem;
  /* top: -18rem; */
}
.text-section p:nth-of-type(2) {
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.84px;
  text-align: center;
  display: inline-block;
  color: #4e4e4e;
  margin-left:80px;
}
.text-section p:nth-of-type(1) {
  font-family: Work Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 44.2px;
  text-align: center;
}
.eva-gif {
  background: url(../../assets/bgeva.png);
  background-size: cover;

  margin-top: -8rem;
  margin-left: 4rem;
  max-width: 100%;
  height: 350px;
  border-radius: 0px 0px 40px 40px;
}
.eva-video {
  border: 2px solid #333;
  max-width: 170px;
  height: 320px;
  border-radius: 40px;
  position: relative;
  top: 10px;
}
.AI {
  float: right;
}
.play-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1220px;
  height: 468px;
  background-color: #f7f5fb;
  margin-top: 40px;
}
.text-section2 {
  position: relative;
  top: -1rem;
  left: 8rem;
}
.text-section2 p:nth-of-type(1) {
  font-family: Work Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 44.2px;
  /* text-align: center; */
  float: left;
}
.text-section2 p:nth-of-type(2) {
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.84px;
  text-align: center;
  display: inline-block;
  color: #4e4e4e;
  /* margin-left: 20px; */
  float: left;
}
.lecture {
  float: left;
}
.mockupimg2 {
  margin-right: 4rem;
}
.starimg3 {
  display: inline;
  position: relative;
  top: -75px;
  left: -28rem;
}
.starimg4 {
  display: inline;
  position: relative;
  top: 25px;
  left: -7rem;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1220px;
  height: 468px;
  background-color: #f7f5fb;
  margin-top: 40px;
}
.mockupimg3 {
  margin-left: 4rem;
}
.text-section3 {
  position: relative;
  top: 20px;
  left: -6rem;
}
.text-section3 p:nth-of-type(1) {
  font-family: Work Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 44.2px;
  /* text-align: center; */
  float: left;
}
.text-section3 p:nth-of-type(2) {
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.84px;
  text-align: center;
  display: inline-block;
  color: #4e4e4e;
  /* margin-left: 20px; */
  float: left;
}
.starimg5 {
  display: inline;
  position: relative;
  top: -4rem;
  left: -12rem;
}
.starimg6 {
  display: inline;
  position: relative;
  top: 11rem;
  left: 33rem;
}
.AI-eva {
  float: right;
}
.text-section4 {
  position: relative;
  left: 8rem;
}
.text-section4 p:nth-of-type(1) {
  font-family: Work Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 44.2px;
  /* text-align: center; */
  float: left;
}
.text-section4 p:nth-of-type(2) {
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.84px;
  text-align: center;
  display: inline-block;
  color: #4e4e4e;
  /* margin-left: 20px; */
  float: left;
}
.starimg7 {
  display: inline;
  position: relative;
  top: -4rem;
  left: -32rem;
}
.starimg8 {
  display: inline;
  position: relative;
  top: 2rem;
  left: -10rem;
}
.analysis {
  float: left;
}

.text-section5 {
  position: relative;
  top: -3rem;
  left: -6rem;
}
.text-section5 p:nth-of-type(1) {
  font-family: Work Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 44.2px;
  /* text-align: center; */
  float: left;
}
.text-section5 p:nth-of-type(2) {
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.84px;
  text-align: center;
  display: inline-block;
  color: #4e4e4e;
  /* margin-left: 20px; */
  float: left;
}
.starimg9 {
  display: inline;
  position: relative;
  top: -2rem;
  left: -15rem;
}
.starimg10 {
  display: inline;
  position: relative;
  top: 10rem;
  left: 32rem;
}
.generation {
  float: right;
}

.analysis-section {
  max-width: 1220px;
  height: auto;
  margin-top: 40px;
  background-color: #f7f5fb;
  overflow: hidden;
}
.text-section6 p:nth-of-type(1) {
  font-family: Work Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 44.2px;
  /* text-align: center; */
  /* float: left; */
}
.text-section6 p:nth-of-type(2) {
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.84px;
  text-align: center;
  display: inline-block;
  color: #4e4e4e;
  /* margin-left: 20px; */
  /* float: left; */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;

  max-width: 820px;

  gap: 0px;
  margin-left: 12rem;

}
.image5 {
  margin-top: -105px;
}
.image6 {
  margin-top: -9.5rem;
}
.image7 {
  position: relative;
  left: 34rem;
  top: -20rem;
}
.image {
  /* overflow: hidden; */
}

.image img {
  max-width: 100%;
  height: auto;
}
.starimg11 {
  display: inline;
  position: relative;
  top: 0rem;
  left: -18rem;
}
.starimg12 {
  display: inline;
  position: relative;
  top: 2rem;
  left: 3rem;
}
.feature-mobile {
  display: none;
}
@media (min-width: 1440px) {
  .feature-section {
    /* max-width: 420px; */
    max-width: 90%;
    max-height: 4800px !important;
    margin-left: 4.5rem;
    text-align: center;
  }
  .play-section{
    max-width:110%;
  }
  .section{
    max-width:110%;
  }
  .analysis-section{
    max-width:110%;
  }
  .image-grid{
    margin-left:16rem;
    /* max-width:100%; */

  }
 
}
@media (min-width: 1520px){
  .image-grid{
    margin-left:18rem;
    

  }
}
@media (min-width: 1620px){
  .image-grid{
    margin-left:20rem;
    

  }
}
@media (min-width: 1720px){
  .image-grid{
    margin-left:25rem;
    

  }
  .feature-section {

   
    margin-left: 5.5rem;
    
  }
}
@media (max-width: 768px) {
  .feature-section {
    /* max-width: 420px; */
    max-width: 90%;
    max-height: 4800px !important;
    margin-left: 2.5rem;
    text-align: center;
  }

}
@media (max-width: 432px) {
  .feature-section {
    /* max-width: 420px; */
    max-width: 611px;
    max-height: 4800px !important;
    margin-left: 2px;
    text-align: center;
  }
  .feature-tag > span {
    font-family: Work Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #7f01fc;

    position: relative;
    top: 30px;
  }
  .feature-tag > p {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .Eva {
    height: 500px;
    border-radius: 30px;
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
  }
  .eva-gif {
    /* margin-top: 10px; */
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .text-section p:nth-of-type(2) {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    /* text-align: center; */
    display: inline-block;
    color: #4e4e4e;
    margin-top: -35px;
    margin-left: 20px;
  }
  .text-section p:nth-of-type(1) {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    /* text-align: center; */
  }
  .text-section {
    /* display: flex; */
    position: relative;
    left: 0rem;
    padding: -10px;
    /* top: -18rem; */
  }
  .AI {
    float: none;
    position: relative;
    top: -20px;
    left: 12px;
  }
  .starimg1 {
    margin-left: -30px;
    margin-top: 20px;
  }
  .starimg2 {
    position: relative;
    top: 10px;
    left: 35px;
  }

  .play-section {
    height: 600px;
    display: flex;
    flex-direction: column;
    align-content: center;

    text-align: center;
    border-radius: 30px;
    margin-top: 10px;
  }
  .text-section2 {
    position: relative;
    top: 2rem;
    left: 50px;
  }
  .text-section2 p:nth-of-type(1) {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    /* text-align: center; */
    /* float: left; */
  }
  .text-section2 p:nth-of-type(2) {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    color: #4e4e4e;
    position: relative;
    left: -50px;
    top: -20px;
  }
  .lecture {
    float: none;
    margin-left: 30px;
  }
  .mockupimg2 {
    position: relative;
    left: 35px;
  }
  .starimg3 {
    position: relative;
    top: -30px;
    left: -18rem;
  }
  .starimg4 {
    position: relative;
    top: 0px;
    left: 30px;
  }
  .section {
    display: flex;
    flex-direction: column-reverse;
    height: 600px;
    margin-top:10px
  }
  .text-section3 p:nth-of-type(1) {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 110px;
    /* margin-top: 20px; */
    /* float: left; */
  }
  .text-section3 p:nth-of-type(2) {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    color: #4e4e4e;
    margin-right: 50px;
    margin-bottom: 30px;

    position: relative;

    top: -20px;
  }
  .text-section3 {
    /* margin-left: 180px; */
    position: relative;
    left: 30px;
    top: 40px;
  }
  .AI-eva {
    float: none;
  }
  .mockupimg3 {
    position: relative;
    left: -30px;
    top: -40px;
  }
  .starimg5 {
    position: relative;
    top: 20px;
    right: 2rem;
  }
  .starimg6 {
    position: relative;
    top: -3rem;
    left: 7rem;
  }
  .text-section4 p:nth-of-type(1) {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 110px;
    /* margin-top: 20px; */
    /* float: left; */
  }
  .text-section4 p:nth-of-type(2) {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    color: #4e4e4e;
    margin-left: -2rem;
    margin-bottom: 30px;

    position: relative;

    top: -20px;
  }
  .text-section4 {
    /* margin-left: 180px; */
    position: relative;
    left: 80px;
    top: 40px;
  }
  .analysis {
    float: none;
    margin-left: 50px;
   
  }
  .starimg7 {
    position: relative;
    top: -40px;
    left: -26rem;
  }
  .starimg8 {
    position: relative;
    /* top: -3rem; */
    left: 4rem;
  }
  .text-section5 p:nth-of-type(1) {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 110px;
    /* margin-top: 20px; */
    /* float: left; */
  }
  .text-section5 p:nth-of-type(2) {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    color: #4e4e4e;
    margin-left: -2rem;
    margin-bottom: 30px;

    position: relative;

    top: -20px;
  }
  .text-section5 {
    /* margin-left: 180px; */
    position: relative;
    left: 30px;
    top: 40px;
  }
  .generation {
    float: none;
  }
  .starimg9 {
    position: relative;
    top: 25px;
    left: -12rem;
  }
  .starimg10 {
    position: relative;
    top: -4rem;
    left: 6rem;
  }
  .analysis-section{
    margin-top:10px;
  }

  .text-section6 p:nth-of-type(1) {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 110px;
    /* margin-top: 20px; */
    /* float: left; */
  }
  .text-section6 p:nth-of-type(2) {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    color: #4e4e4e;
    margin-left: -6.5rem;
    margin-bottom: 30px;

    position: relative;

    top: -20px;
  }
  .span-aper {
    margin-left: 50px;
    position: relative;
    top: -12px;
  }
  .text-section6 {
    /* margin-left: 180px; */
    position: relative;
    left: 60px;
    /* top: 40px; */
  }
  .image-grid {
    display: none;
  }
  .starimg11 {
    position: relative;
    top: 15px;
    left: -13rem;
  }
  .starimg12 {
    position: relative;
    top: 2rem;
    left: 0rem;
  }
  .live-feed {
    width: 400px;
    height: 445px;
  }
  .image-container {
    height: 400px;
  }
  .feature-mobile {
    overflow: hidden; /* Ensure that the overflow is hidden to hide images outside the container */
  }
  .live-feed > p {
    height: 35px;
    background: #7f01fc;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
  }
  .live {
    /* background: url(../../assets/livefeed1.jpeg); */
  }

  .image-container {
    display: flex;
    width: 195px;
    overflow: hidden;
    margin-left: 7rem;
  }

  @keyframes slideImage1 {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideImage2 {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .image-container img:nth-child(1) {
    animation: slideImage1 5s ease-in infinite alternate; /* Adjust timing as per your requirement */
    /* z-index: 1; */
  }

  .image-container img:nth-child(2) {
    animation: slideImage2 5s ease-in infinite alternate; /* Adjust timing as per your requirement */
  }

  .image-container img {
    width: 100%; /* Ensure images fill the container */
    flex-shrink: 0; /* Prevent images from shrinking */
  }
  .feature-mobile {
    display: block;
  }
}
@media (max-width: 415px) {
  .text-section4 {
    /* margin-left: 180px; */
    position: relative;
    left: 80px;
    top: -20px;
  }
  .starimg7 {
    position: relative;
    top: 15px;
    left: -14rem;
  }
  .mockupimg2 {
    /* position: relative;
       top: -20px; */
    margin-top: -50px;
  }
}
