
   .navbar{
    margin: 0;
    background:#F7F5FB;
   }
.logoo{
    margin-left: 80px;
}
.list-container{
 
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.download{
    text-align: center;
    
    margin-left: 90px;
    margin-right: 80px;

}
.download>a:hover{
  color: #fff;
}
.navbar-nav{
   
    margin-right: 40px;
    gap: 75px;
   

}
.nav-link{
    color: black;
    font-family: Work Sans;
  
}
.nav-link:hover{

    color:#7F01FC;
}
.download button{
    margin-top: 5px; 
    margin-left: 30px;
    background-color:transparent;
    border: none;
    color: white;
}
.download img{
    margin-right: 120px;
    margin-top: -54px;
}
.custom-toggler {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: 40px;
    height: 40px;
  }
  
  .bar {
    display: block;
    width: 30px;
    height: 4px;
    background-color: #333;
    margin: 6px auto;
    transition: background-color 0.3s ease;
    /* margin-left: -20px; */
    overflow-x: hidden;
  }
  
  .custom-toggler.active .bar:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }
  
  .custom-toggler.active .bar:nth-child(2) {
    opacity: 0;
  }
  
  .custom-toggler.active .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
  
  .navbar-collapse {
    display: none;
  }
  
  .navbar-collapse.show {
    display: block;
  }
  .nav-item ul:hover{
    color: #7F01FC;
  }
  
  


@media screen and (max-width:980px){
    .download{
        display: none;
    }
    .navbar{
   
      background-color: #fff;
      text-align: center;
    }
    .navbar-nav{
       gap: 20px;
    }

}
@media screen and (max-width:432px){
  .logoo{
    margin-left: 0;
  }
  .navbar{
   
    background-color: #fff;
    text-align: center;
  }
  .navbar-nav{
     gap: 20px;
  }
  
}
/* Define CSS for larger screens (width ≥ 992px) */

