/* FAQs.css */

.faqs-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
  }
  .faqs-container > p {
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #7f01fc;
    margin-bottom: 40px;
    text-align: center;
  }
  .faq-container {
    border: 1px solid #646363;
    margin-bottom: 10px;
    border-radius: 8px;
    width: 800px;
    margin-left: 40px;
    /* height: 60px; */
  }
  
  .faq-question {
    border-radius: 8px;
  
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Work Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    /* background: */
  
  
  }
  
  .faq-question.clicked {
    background-color: #f0e9ff;
  }
  .faq-question:hover {
    background: #f0e9ff;
  }
  
  .faq-question .icon {
    cursor: pointer;
  }
  
  .faq-answer {
    /* display: none; */
    padding: 15px;
    border-radius: 8px;
    font-family: Segoe UI;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  
  .faq-answer.show {
    display: block;
  }
  @media screen and (max-width: 432px){
    .faq-container{
      width: 330px;
      margin-left: 30px;
      overflow: auto;
    }
    .faqs-container  .faq-question{
      font-size: 16px;
    }
  }
  @media (min-width: 1440px){
    
  
  }