.row{
    margin-left: 12rem;
}
@media screen and (max-width:432px){
    .row{
        margin-left: 60px;
       
    }
    .contactus p:nth-child(2) {
    
        text-align: center;

    }
    .img-contact img{
        width: 80%;
        margin-left: 45px;
    }
}