.hero-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7rem;
    overflow: hidden;
   
}
.hero-right{
    background: url(../../assets/bgnewsvg.svg);
    margin-right: 0px;
    /* max-width: 511px; */
    position: relative;
    left: 62px;
    
}
.mockup1-img{

    position: relative;
    left: -50px;
}
.hero-left{
    margin-left: 6rem;
}
.text > span {
    /* margin-left: -21rem;   */
    color: #7f01fc;
    font-weight: 700;
    font-size: 60px;
    font-family: Work Sans;
    text-align: left;
    
  }
  .text > .para1 {
    font-family: Work Sans;
    font-size: 32px;
    font-weight: 500;
    line-height: 51.61px;
    text-align: left;
  }
  .text > .para2 {
    font-family: Work Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 29.43px;
    text-align: left;
    color: #5B5B5B;
   
  
  }
  .btn {
    float: left;
    width: 235px;
    height: 54px;
    top: 275px;
    gap: 8px;
    border-radius: 34px;
    background: #5f00bd;
    color: white;
    margin-top: 3rem;
    /* opacity:0; */
  }
  .btn button{
      margin-top: 5px;
      background-color: transparent;
      border: none;
      color: white;
  }
  .downloadApp>button{
    display: none;
    float: left;
    width: 235px;
    height: 54px;
    top: 275px;
    gap: 8px;
    border-radius: 34px;
    opacity: 0px;
    background: #5f00bd;
    color: white;
    margin-top: 3rem;
  }
  .hero-right1{
    display: none;
  }
  @media (max-width: 432px) {
    .hero-section {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        max-width: 460px;
        margin-top: 50px;
        /* overflow: auto; */
    }
    .hero-right{
        border: 2px solid red;
        width: 400px;
        overflow: auto;
        position:relative;
        left: 0rem;
        top: -2rem;
        margin-top: 20px;
        display: none;
        
        
      
    }
    .hero-right1{
        display: block;
        width: 100%;
        height: 443px;
      
        position:relative;
        left: -14px;
        top: -rem;
        margin-top: -30px;
        

    }
    .mockup1-img{
        width: 100%;
        /* position: relative;
        left: 0rem;
        top: 2rem; */
        margin-left: 4rem;
        margin-top: 0px;

    }
    .text > span{
        margin-bottom: -10px;
        font-size: 34px;
        margin-left: -5rem;
        line-height: 72px;
    }
    .text > .para1{
        font-size: 18px;
        text-align: center;
        margin-left: -5rem;
        margin-top: -20px;
 
    }
    .text > .para2{
        font-size: 16px;
        text-align: center; 
        margin-left: -6rem;
       
        margin-top: -20px;
        text-wrap: wrap;
 
    }
    .downloadApp{
        display: block;
       
    }
    .downloadApp>button{
        margin: 15px;
        display: block;

    }
    .bt-cont{
        display: flex;
       
        gap: 80px;
    }
    .btn{
        display: flex;
        justify-content: center;
        align-items: center;
       
       width: 180px;
       margin-left:-70px;
       border-radius: 25px;
       
    }
    .btns{
        /* margin-left:-20px; */

    }
    
    
}