
.testimonial-container{
    max-width: 1220px;
    height: 620px;
  
    margin-top: 60px;
    overflow: hidden;
}
.testimonial-container>p{
  
    overflow: hidden;
font-family: Work Sans;
font-size: 24px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.05em;
color: #7F01FC;
text-align: center;



}
.testi-img>img{
    overflow-x: hidden;
    margin-top: 40px;
    margin-left: 50px;
}
.testimonial-mobile{
    display: none;
}
@media screen and (max-width: 432px){
    .testi-img{
        display: none;
    }
    .testimonial-container{
        height: 280px;
    }
    .testimonial-mobile{
        margin-left: 30px;
        display:block;
    }
    .testimonial-container>p{
        font-size: 18px;
    }
}
@media (min-width: 1440px){
    .testimonial-container{
      max-width:100%;
      margin-left:8rem;
    }
    .testimonial-container>p{
        margin-left:-14rem;
  
    }
  
  }
  @media (min-width: 1620px){
    .testimonial-container{
      max-width:100%;
      margin-left:10rem;
    }
    .testimonial-container>p{
        margin-left:-14rem;
  
    }
  
  }
  @media (min-width: 1720px){
    .testimonial-container{
      max-width:100%;
      margin-left:14rem;
    }
    .testimonial-container>p{
        margin-left:-14rem;
  
    }
  
  }