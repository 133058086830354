/* Footer.css */

.footer {
    /* border: 2px solid red; */
  background: #7f01fc;
  color: white;
  

  padding: 20px;
  max-width: 1440px;
  /* height: 393.13px;   */
  /* top: 6167px; */
  padding: 16px 0px 16px 0px;
  /* margin-left: -20px;    */
  overflow-x: hidden;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow-x: hidden;

}

.footer-logo img {
  width: 230px; /* Adjust width as needed */
  height: 55px;
  margin-left: 70px;
  overflow-x: hidden;

}
.footer-app-links {
  display: flex;
  align-items: center;
  margin-right: 8rem;   
  text-align: center;
  overflow-x: hidden;

}
.footer-app-links > P {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  overflow-x: hidden;

}
.footer-app-links a {
  margin-left: 10px;
  color: #000;
  overflow-x: hidden;

}

.footer-middle {
  display: flex;
  flex-wrap: wrap; /* Wrap sections on smaller screens */
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 3.5rem;
  overflow-x: hidden;

}

.footer-section {
  flex: 1;
  margin-bottom: 10px; /* Add margin between sections */
  overflow-x: hidden;

}
.footer-section img {
  /* display: inline-block;  */
  align-items: center;
  overflow-x: hidden;

}
.footer-section li {
  /* border: 2px solid red; */
}


.footer-section h4 {
  margin-bottom: 10px;
  margin-left: 20px;
  /* overflow: hidden; */

}
.footer-section img{
  
    margin-left: -10px;
}
.footer-section ul li:nth-child(1) img {
    
 

  }
  .social-media ul li:nth-child(2){
    
  
    margin-left: 5px;
  }
  
  .footer-section ul li:nth-child(3) {

    margin-left: -7px;
  }
  
  .social-media ul li:nth-child(4) {
    
    margin-left: -9px;
  }
  .social-media ul{
    margin-top:40px;
  }
  .follow{
    margin-right: -7rem;
  }
  .facebook ul {
  
    margin-top: -20px;
    margin-left: -8px;
  }
  .facebook ul li:nth-child(2) {
    
    margin-left: -5px;
  }
  
    


.footer-bottom {
  display: flex;
  flex-wrap: wrap; /* Wrap sections on smaller screens */
  justify-content: space-between;
  overflow-x: hidden;

}
.footer-terms {
  margin-left: 70px;
  overflow-x: hidden;

}
.footer-terms a {
  margin-right: 10px;
  color: #fff;
  overflow-x: hidden;

}

.footer-copyright {
  display: flex;
  align-items: center;
  overflow-x: hidden;

}

.footer-copyright span {
  margin-right: 10rem;
  overflow-x: hidden;

}
.follow{

}

/* Media queries for responsiveness */
/* @media screen and (max-width: 768px) {
  .footer-top {
    flex-direction: column; 
    align-items: flex-start; 
  }

  .footer-app-links {
    margin-top: 10px;
  }
} */
@media screen and (max-width: 432px) {
    .footer{
       max-height:800px;
       width: 100%;
       
       margin: 0 auto;
       /* display: flex; */
       
    }
 .footer-top{
    display: flex;
    flex-direction: column;
    align-items: center;
 }
 .footer-logo{
    margin-left: -70px;
    margin-bottom: 30px;
 }
 .footer-app-links img{
   
    width: 150px;  
    /* margin-left: 6rem;   */
 }
 .footer-app-links{
    margin-left: 7rem;
 }
 .footer-middle{
    display: flex;
    flex-direction: column;
 }
 .footer-section{
     }
 .footer-section h4{
    font-size: 16px;
 }
 .company{
    position: relative;
    /* bottom: 6.4rem; */
    left: -20px;
 }
 
 .company ul li:nth-child(1) {
    
  margin-left: -12px;
}
.company ul li:nth-child(2) {
    
  margin-left: -12px;
}
 .resources{
    position: relative;
    bottom: 6.4rem;
    left: 11rem;
 }
 .resources ul li:nth-child(1) {
    
  margin-left: -12px;
}
.resources ul li:nth-child(2) {
    
  margin-left: -12px;
}
 .social-media{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    /* position: relative;
    bottom: 4rem;
    left: 5rem; */
    text-align: center;
    /* margin-left: -30px; */
 }
 .social-mediaa{
   margin-top: -40px;
 }
 .follow{
   
    text-align: center;
    margin-top: -90px;
    margin-left: -12rem;
 }
 .follow>h4{
   font-size: 16px;
 }
 .social-mediaa ul li:nth-child(1) {
    
  margin-left: -35px;
}
.social-mediaa  ul li:nth-child(2) {
    
  
 
  position: relative;
  left: -20px;
}
 .facebook{
   
    margin-right: 70px;
  
 }
 .facebook ul{
  margin-top: 5px;
  margin-right: 20px;
 }
 .contact{
   
    text-align: center;
    margin-left: -90px;
 }
 .contact>P{
  margin-left: 20px;
 }
.footer-terms{
    display: flex;
    justify-content: space-between;
    gap: 110px;
    margin-left: 20px;
}
.footer-copyright{
   
  
    position: relative;
    left: 9rem;
    top: 5px;
}
.follow{
  font-size: 16px;
}
}
@media (min-width: 1440px){
  .footer{
    max-width:100%;
  }

}